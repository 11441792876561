import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {

  @Input() required!: boolean;
  @Input() checked!: boolean;

  @Output() onClickEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();

    if(!this.required){
      this.onClickEvent.emit();
    }
  }
}
