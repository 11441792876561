// MATERIAL
import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {AccordionComponent} from "../accordion/accordion.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {ToggleSwitchComponent} from "../toggle-switch/toggle-switch.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {CookiesConsentService} from "./services/cookies-consent.service";
import {CookiePreferences} from "./models/cookie-preferences";
import {MenuItem} from "../accordion/models/menu-item";
import {RippleDirective} from "../../directives/ripple.directive";
import {CdsButtonComponent, CdsSvgIconComponent} from "@carool1/ngx-carool-ds";
import {UiService} from "../../services/ui.service";

@Component({
    selector: 'app-cookies-consent',
    templateUrl: './cookies-consent.component.html',
    styleUrls: ['./cookies-consent.component.scss'],
  imports: [
    TranslateModule,
    AccordionComponent,
    NgForOf,
    ToggleSwitchComponent,
    NgIf,
    RippleDirective,
    CdsSvgIconComponent,
    CdsButtonComponent,
    NgClass
  ]
})
export class CookiesConsentComponent implements OnInit, OnDestroy {

  private readonly onDestroy$ = new Subject();

  switches: CookiePreferences = {
    functionality: { value: true, required: false },
    performance: { value: true, required: false },
    essential: { value: true, required: true }
  };

  preferences = false;
  menuItems: MenuItem[] = [];
  listItems: string[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              protected ui: UiService,
              private cookiesService: CookiesConsentService,
              private translate: TranslateService) { }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  ngOnInit(): void {
    this.translate.get('landing.cookies.list.items').pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.listItems = Object.values(value) as string[];
    });
    this.translate.get('landing.cookies.menus.items').pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      const items: any = Object.values(value);

      for(const [i, value] of items.entries())
        this.menuItems[i] = { active: false, ...value };
    });
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      if(params['preferences'] === 'true'){
        this.preferences = true;
        const preferences = this.cookiesService.getCookiePreferences();
        if(preferences)
          this.switches = preferences;
      }
    });
  }

  async goBack() {
    const url = new URL(this.cookiesService.getLastUrl(), window.location.origin);
    const queryParams = Object.fromEntries(url.searchParams.entries());
    await this.router.navigate([url.pathname], {queryParams});
  }

  onConsentClick(consent: boolean) {
    // Only performance cookies for now
    if (consent) {
      this.cookiesService.acceptCookies(this.switches);
    } else {
      this.cookiesService.refuseCookies();
    }
    this.goBack();
  }

  onMenuToggle(menuItem: MenuItem) {
    this.menuItems.forEach((item) => item.active = item === menuItem ? !item.active : false);
  }

  onSwitchToggle(index: number) {
    this.getSwitches()[index].value = !this.getSwitches()[index].value;
  }

  computeRequired(i: number) {
    return this.getSwitches()[i]?.required;
  }

  computeChecked(i: number) {
    return this.getSwitches()[i]?.value;
  }

  private getSwitches() {
    return Object.values(this.switches) || [];
  }
}
